import {
  type Dispatch,
  type HTMLAttributes,
  type ReactNode,
  type SetStateAction,
  useState,
} from "react";
import { NokPrice, onDesktop } from "@naf/teamscheme";
import styled from "styled-components";
import type { To } from "react-router-dom";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import {
  RadioButtonElement,
  RadioInputElement,
  RadioLabel,
  RadioLabelWrapper,
} from "../../../forms/RadioGroup";
import BenefitList from "../../../shared/elements/BenefitList";
import { NafLogo } from "../../../shared/logos/NafLogo";
import {
  ABBREVIATED_PREMIUM_BENEFITS,
  ABBREVIATED_SIMPLE_BENEFITS,
} from "./AbbreviatedBenefits";
import { SelectedContractTypeBanner } from "./SelectedContractTypeBanner";
import { PRICES } from "./PRICES";
import type { PackageType } from "./PackageType";

const StyledPrice = styled(NokPrice)`
  font-weight: normal;
  white-space: nowrap;
`;
const OptionContent = styled.div`
  width: 100%;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
`;

const OptionTag = styled.div`
  ${({ theme }) => theme.fontStyle.componentText.tag}
  position: absolute;
  right: ${({ theme }) => theme.spacing.space32};
  top: -${({ theme }) => theme.spacing.space8};
  background: ${({ theme }) => theme.nafColor.primary.moss};
  color: ${({ theme }) => theme.nafColor.primary.spruce};
  padding: ${({ theme }) => theme.spacing.space4};
`;
const Option = styled.div
  .attrs<{ checked: boolean }>({})
  .withConfig({ shouldForwardProp: (prop) => prop !== "checked" })`
  position: relative;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.space16};
  border: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral3};
  border-radius: ${({ theme }) => theme.radius.m};
  background: ${({ checked, theme }) =>
    checked ? theme.nafColor.signature.yellow10 : "white"};
`;
const OptionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.space16};
  margin-bottom: ${({ theme }) => theme.spacing.space16};
  flex-direction: column;

  ${onDesktop`
    flex-direction: row;
  `}
`;
const OptionName = styled.span`
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.spacing.space4};
`;
const OptionInputWrapper = styled.div`
`;

const OPTION_NAMES: {
  [contractType in PackageType]: string;
} = {
  [ContractType.Simple]: "Enkel",
  [ContractType.Premium]: "Premium",
  [ContractType.Member]: "Bli medlem",
};
const OptionDetails = styled.div`
  ${onDesktop`
    display: none;
  `}
`;

const RadioLineWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.space16};
  ${onDesktop`
    margin-bottom: 0;
  `}
`;

function PackageOption({
  packageType,
  current,
  onChange,
  name,
  price,
  children,
  tag: label,
}: {
  packageType: PackageType;
  current: PackageType;
  onChange: Dispatch<SetStateAction<PackageType>>;
  name: string;
  price: number;
  children?: ReactNode;
  tag?: string;
}) {
  return (
    <Option checked={packageType === current}>
      {label && <OptionTag>{label}</OptionTag>}
      <OptionInputWrapper>
        <RadioLabel>
          <RadioLabelContent>
            <RadioLineWrapper>
              <RadioInputElement
                type="radio"
                name="ContractType"
                checked={packageType === current}
                onChange={() => onChange(packageType)}
              />
              <RadioButtonElement />
              <RadioLabelWrapper>
                <OptionContent>
                  <OptionName>{name}</OptionName>
                  <StyledPrice value={price} />
                </OptionContent>
              </RadioLabelWrapper>
            </RadioLineWrapper>
            <OptionDetails>{children}</OptionDetails>
          </RadioLabelContent>
        </RadioLabel>
      </OptionInputWrapper>
    </Option>
  );
}
const RadioLabelContent = styled.div`
  width: 100%;
`;
const OptionBenefitList = styled(BenefitList)`
  margin-bottom: 0;
  /* Align with radio buttons */
  margin-left: ${({ theme }) => theme.spacing.space4};
`;
const MembershipOptionDetails = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <div>
        Alt du får i Premium. I tillegg får du hjelp og bistand i etterkant av
        juridiske og tekniske eksperter.
      </div>
      <div>
        <NafLogo size={36} />
      </div>
    </div>
  );
})`
  display: flex;
  gap: ${({ theme }) => theme.spacing.space16};
  align-items: flex-end;
`;

export function TypeSelector({
  links,
}: { links: { [key in PackageType]: To } }) {
  const [contractType, setContractType] = useState<PackageType>(
    ContractType.Premium,
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <OptionsContainer>
        <PackageOption
          packageType={ContractType.Simple}
          current={contractType}
          onChange={setContractType}
          name={OPTION_NAMES[ContractType.Simple]}
          price={PRICES[ContractType.Simple].amount}
        >
          <OptionBenefitList benefits={ABBREVIATED_SIMPLE_BENEFITS} />
        </PackageOption>
        <PackageOption
          packageType={ContractType.Premium}
          current={contractType}
          onChange={setContractType}
          name={OPTION_NAMES[ContractType.Premium]}
          price={PRICES[ContractType.Premium].amount}
          tag="Populær"
        >
          <OptionBenefitList benefits={ABBREVIATED_PREMIUM_BENEFITS} />
        </PackageOption>
        <PackageOption
          packageType={ContractType.Member}
          current={contractType}
          onChange={setContractType}
          name={OPTION_NAMES[ContractType.Member]}
          price={PRICES[ContractType.Member].amount}
        >
          <MembershipOptionDetails />
        </PackageOption>
      </OptionsContainer>
      <SelectedContractTypeBanner contractType={contractType} links={links} />
    </form>
  );
}
