import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { LONG_PACKAGE_NAMES, SHORT_PACKAGE_NAMES } from "./ComparisonTable";
import { Button, NokPrice } from "@naf/teamscheme";
import Banner from "../../../shared/elements/Banner";
import styled from "styled-components";
import { EVENTS } from "../../../Tracking";
import TrialMembershipInfoModalTextLink from "../trial-membership/TrialMembershipInfoModalTextLink";
import type { PackageType } from "./PackageType";
import { PRICES } from "./PRICES";
import type { Path, To } from "react-router-dom";

const SelectButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space8};
`;

const ProductBanner = styled(Banner).attrs({ variant: "yellow" })`
  p {
    margin: ${({ theme }) => theme.spacing.space8} 0;
  }
`;

const LargePrice = styled(NokPrice)`
  ${({ theme }) => theme.fontStyle.headers.header1}
`;

export function SelectedContractTypeBanner({
  contractType,
  links,
}: {
  contractType: PackageType;
  links: {
    [key in PackageType]: To;
  };
}) {
  return (
    <ProductBanner variant="yellow">
      <div>
        <p>{LONG_PACKAGE_NAMES[contractType]}.</p>
        <p>
          <LargePrice value={PRICES[contractType].amount} />
        </p>
        {contractType === ContractType.Premium && (
          <p>
            Inkl. Prøvemedlemskap med veihjelp i 30 dager.{" "}
            <TrialMembershipInfoModalTextLink />
          </p>
        )}
        {contractType === ContractType.Simple && (
          <p>NAF-medlemmer får Premium uten ekstra kostnad.</p>
        )}
        {contractType === ContractType.Member && (
          <p>Medlemskap i 12 måneder, og gir tilgang til Premium. </p>
        )}
        <SelectButton
          type="submit"
          variant="primary"
          linkTo={links[contractType]}
          trackEvent={{
            ...EVENTS.CONTRACT_TYPES.SELECT_OPTION,
            label: `Velg ${SHORT_PACKAGE_NAMES[contractType]}`,
            value: SHORT_PACKAGE_NAMES[contractType],
          }}
        >
          Velg {SHORT_PACKAGE_NAMES[contractType]}
        </SelectButton>
      </div>
    </ProductBanner>
  );
}
